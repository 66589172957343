/*引用公用样式*/
@import './animation.less';
@import './global.import.less';
@import './bgm.less';
// @import './font.less';

/*项目样式*/
body {
    background-color: #fff;
}

#screen {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
}

#dom-main-box {
    position: absolute;
    left: 0;
    top: 0;
}

#pixi-main-box {
    position: absolute;
    left: 0;
    top: 0;

    canvas {
        position: absolute;
        left: 0;
        top: 0;
    }
}

#createjs-main-box {
    position: absolute;
    left: 0;
    top: 0;

    canvas {
        position: absolute;
        left: 0;
        top: 0;
    }
}

#threejs-main-box {
    position: absolute;
    left: 0;
    top: 0;

    canvas {
        position: absolute;
        left: 0;
        top: 0;
    }

}

#vue-main-box {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 20px;
}

#load-main-panel {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #ccc;
}



