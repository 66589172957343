@keyframes shake-horizontal {

    0%,
    to {
        transform: translateX(0);
        opacity: 1;
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-2px);
        opacity: 1;
    }

    20%,
    40%,
    60%,
    80% {
        transform: translateX(2px);
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateX(-2px);
    }
}


@keyframes antCheckboxEffect {
    0% {
        transform: scale(1);
        opacity: .5
    }

    to {
        transform: scale(1.6);
        opacity: 0
    }
}