#bgm-btn {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 75px;
    height: 75px;
    //z-index: 10000;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: none;

    .on,
    .off {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 75px;
        height: 75px;
    }

    .on {
        background: url(../assets/images/openBtn.png) no-repeat center;
        // animation: bgmove 1.1s linear infinite;
        // -webkit-animation: bgmove 1.1s linear infinite; 
        display: none;
    }

    .off {
        background: url(../assets/images/closeBtn.png) no-repeat center;
        display: none;
    }

    @keyframes bgmove {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes bgmove {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}